import React from 'react'
import RestHelper from '../Helpers/RestHelper'
// import UIHelper from '../Helpers/UIHelper'

export const ProductsContext = React.createContext()

const initMasters = { brands: [], segments: [], genders: [], models: [], styles: [], sizes: [], Colors: [] }
export const ProductsProvider = ({ children }) => {
	const [ masters, setMasters ] = React.useState(initMasters)
	const [ products, setProducts ] = React.useState([])

	const getMasters = () =>
		new Promise((resolve, reject) => {
			RestHelper.get('app/masters')
				.then((result) => {
					if (result) {
						setMasters(result)
						resolve(result)
						// setTimeout(() => resolve(result), 1000)
					} else {
						setMasters(initMasters)
						reject('Error: Failed to load masters')
					}
				})
				.catch((err) => reject(err))
		})
	const saveMaster = (master, obj) =>
		new Promise((resolve, reject) => {
			if (obj.id === 0) {
				RestHelper.post('masters/' + master, obj)
					.then((result) => {
						if (result) {
							setMasters({ ...masters, [master + 's']: result })
							resolve(result)
							// setTimeout(() => resolve(result), 1000)
						} else {
							//setMasters(initMasters)
							reject('Error: Failed to load masters')
						}
					})
					.catch((err) => {
						alert(err)
						reject(err)
					})
			} else {
				RestHelper.put('masters/' + master, obj)
					.then((result) => {
						if (result) {
							setMasters({ ...masters, [master + 's']: result })
							resolve(result)
							// setTimeout(() => resolve(result), 1000)
						} else {
							setMasters(initMasters)
							reject('Error: Failed to load masters')
						}
					})
					.catch((err) => reject(err))
			}
		})

	const getProducts = (pro) =>
		new Promise((resolve, reject) => {
			if (pro && pro.brand && pro.segment && pro.gender && pro.model) {
				RestHelper.get(
					`app/products?brandId=${pro.brand.id}&segmentId=${pro.segment.id}&genderId=${pro.gender
						.id}&modelId=${pro.model.id}`
				)
					.then((result) => {
						if (result) {
							setProducts(result)
							resolve(result)
							//setTimeout(() => resolve(result), 1000)
						} else {
							setProducts([])
							reject('Error: Failed to load products')
						}
					})
					.catch((err) => reject(err))
			} else {
				resolve([])
			}
		})
	const UploadPTFile = (obj) =>
		new Promise((resolve, reject) => {
				RestHelper.post('app/UploadPTFile', obj, 'file')
					.then((result) => {
						if (result) {
							resolve(result)
						} else {
							reject('Error: Failed to load PT FIle')
						}
					})
					.catch((err) => {
						alert(err)
						reject(err)
					})
			
		})
	const mergeMasterItems = (type,list) =>
		new Promise((resolve, reject) => {
				RestHelper.post('app/MergeMasterItems', {type, list})
					.then((result) => {
						if (result) {
							setMasters({...masters, [type]: result})							
							resolve(true)
						} else {
							reject('Error: Failed to load PT FIle')
						}
					})
					.catch((err) => {
						alert(err)
						reject(err)
					})
			
		})


	return (
		<ProductsContext.Provider value={{ masters, getMasters, saveMaster, UploadPTFile, mergeMasterItems, products, getProducts }}>
			{children}
		</ProductsContext.Provider>
	)
}
