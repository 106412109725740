import React from 'react'

export default function DropDown({ label, items, value, valueField, handleChange, manage, manageModelId}) {
    const handleClick = e => {
        e.preventDefault()
        manage()
    }
    return (
            <div>
            <div className="d-flex justify-content-between">
                <h6>{label}</h6>
                {manage && <a href='#manage' type="text" className=""
                    data-bs-toggle="modal" data-bs-target={"#" + manageModelId}
                    onClick={handleClick} >Manage</a>}
            </div>
                    <div className="btn-group w-100" role="group">
                        <button type="button" className="btn btn-primary dropdown-toggle w-100" data-bs-toggle="dropdown" >
                        {value ? value[valueField] : ' -- select -- '}
                        </button>
                <div className="dropdown-menu w-100 shadow p-1 mb-5 bg-body rounded" >
                            {items && items.length > 0 && items.map(item => {
                                return <button
                                    key={valueField ? item[valueField] : item}
                                    onClick={() => handleChange(item)}
                                    className={"dropdown-item p-2" + (value && value === item ? ' active' : '')} >
                                    {valueField ? item[valueField] : item}
                                </button>
                            })}
                        </div>
                    </div>
            </div>
    )
}
