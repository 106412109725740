import React, { useContext } from 'react'
import { GlobalContext } from '../../Contexts'

export default function Profile() {
    const { auth, logOut } = useContext(GlobalContext.Auth)

    return (
        <>
            {auth.loggedIn === true && (
                <ul className="navbar-nav ms-lg-auto">
                    <li className="nav-item dropdown me-0">
                        <button className="btn btn-primary" href="#" id="navigationLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="text-white">{auth.name}</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right text-capitalize shadow-lg p-2" aria-labelledby="navigationLink">
                            <button className="dropdown-item">Profile</button>
                            <button className="dropdown-item" onClick={() => logOut()} >Logout</button>
                        </div>
                    </li>

                </ul>)
                // <button className="btn btn-primary" onClick={() => logIn({ id: 1, name: 'Admin', role: 'admin' })}  >
                //     Login
                // </button>

            }

        </>
    )
}
