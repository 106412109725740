import React, { useState, useContext } from 'react'
import { GlobalContext } from '../../Contexts'
import TextBox from '../../Controls/TextBox'


export default function Home() {

    const { loadCookie, verifyLogin } = useContext(GlobalContext.Auth)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const submitLogin = (e) => {
        e.preventDefault()
        verifyLogin(username, password)
    }

    return (
        <form onSubmit={submitLogin}>
            <div className='m-auto mt-5 p-5' style={{ width: 600, border: '1px solid #ccc', minHeight: 350, borderRadius: 20 }}>
                <TextBox label='Username' value={username} handleChange={val => setUsername(val)} />
                <TextBox label='Password' type='password' value={password} handleChange={val => setPassword(val)} />
                <div className='m-auto mt-5 ' style={{ width: 200 }}>
                    <button type='submit' className='btn btn-primary w-100'>Login</button>
                    <button type='button' onClick={() => loadCookie()} className='btn btn-primary w-100'>Set</button>
                    {/* <button type='button' onClick={() => setCookie('user', null, { path: "/" })} className='btn btn-primary w-100'>Remove</button> */}
                </div>
            </div>
        </form>
    )
}
