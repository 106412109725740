/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const getJsonPathValue = (json,path) => {
    try {
        if (!json || path==='') return ''
        if (!path.includes('.')) {
            return json[path]
        } else {
            return getJsonPathValue(json[path.split('.')[0]],path.substring(path.indexOf('.') + 1,path.length))
        }
    } catch {
        return ''
    }
}

export default function DisplayTable({ columns, data, style }) {
    return (
        // <div className="" style={{height: 30}}>
        //     <table className="table" style={{padding:0, margin:0}} >
        //         <thead>
        //             <tr className="table-active">
        //                 {columns && columns.map((column) => {
        //                     return <th className="col-1" key={column} scope="col">{column.replace('.name','').toUpperCase()}</th>
        //                 })}
        //             </tr>
        //         </thead>
        //     </table>
        //     </div>
            <div style={{ border: "1px solid", overflow: "auto", ...style }}>
            <table className="table" style={{ padding: 0, margin: 0, border: "1px solid", overflow: "auto", ...style }}>
                <thead>
                    <tr className="table-active">
                        {columns && columns.map((column) => {
                            return <th className="col-1" key={column} scope="col">{column.replace('.name', '').toUpperCase()}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {columns && data && data.map((row,ind) => {
                        return <tr key={ind}>
                            {columns && columns.map((column) => {
                                return <td className="col-1" key={column + '_' + ind}>{getJsonPathValue(row,column)}</td>
                            })}
                    </tr>
                    })}
                </tbody>
            </table>
            </div>
        
    )
}
