/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../Contexts'
import DropDown from '../../Controls/DropDown'
import SelectionList from '../../Controls/SelectionList'
import DisplayTable from '../../Controls/DisplayTable'
import _ from 'lodash'

export default function Products() {
    const { masters, getMasters, saveMaster, UploadPTFile, mergeMasterItems, products, getProducts } = useContext(GlobalContext.Products)
    const [product, setProduct] = useState({})
    const [productFilter, setProductFilter] = useState({})
    const [manage, setManage] = useState(null)
    const [editItem, setEditItem] = useState({ id: 0, name: '' })
    const [editOrg, setEditOrg] = useState(null)
    const [importObj, setImportObj] = useState(null)
    const [ptObj, setPtObj] = useState(null)

    const changeProductSelect = (key, value) => {
        setProductFilter({ ...productFilter, [key]: value })
        setProduct({ ...product, [key]: value })
    }
    const changeProductProp = (key, value) => {
        setProduct({ ...product, [key]: value })
    }

    useEffect(() => {
        getMasters().then(() => console.log('Master load')).catch((err) => alert(JSON.stringify(err)))
    }, [])
    useEffect(() => {
        if (masters.brands.length === 0) return
        setProductFilter({
            brand: masters.brands[0],
            segment: masters.segments[0],
            gender: masters.genders[0],
            model: masters.models[0]
        })
        setProduct({
            brand: masters.brands[0],
            segment: masters.segments[0],
            gender: masters.genders[0],
            model: masters.models[0]
        })
    }, [masters])
    useEffect(() => {
        getProducts(productFilter)
    }, [productFilter])

    const showEditItem = row => {
        setEditItem(row)
        setEditOrg(row)
    }
    const saveEditItem = e => {
        saveMaster(manage, editItem).then((result) => {
            setEditItem({ id: 0, name: '' })
        }).catch((err) => {
            alert(err)
            setEditItem({ id: 0, name: '' })
        })
    }

    const showImportDialog = () => {
        setImportObj({ type: 'master', ptFile: null })
        setPtObj(null)
    }

    const selectFile = (e) => {
        if (e.target.files.length > 0) {
            setImportObj({ ...importObj, 'ptFile': e.target.files[0] })
        }
    }

    const submitImportForm = e => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('file', importObj.ptFile)
        UploadPTFile(formData).then((result) => {
            //console.log(result)
            //alert(JSON.stringify(result))
            setPtObj({ ...result, type: importObj.type })
        }).catch((err) => {
            alert(err)
            setPtObj(null)
        })
    }

    const getMasterList = e => {
        let list = []
        let col = -1;
        ptObj.tab.columns.forEach((c, i) => {
            if (c === ptObj.column) col = i
        })
        if (col >= 0) {
            ptObj.tab.rows.forEach(r => {
                if (!list.includes(r[col])) list.push(r[col])
            })
            setPtObj({ ...ptObj, list: list })
        } else {
            alert('Invalid column selected.')
        }
    }

    return (
        <>
            <h5>Products</h5>
            {masters && <div className="row">
                <div className="col-sm-2">
                    <DropDown label='Brand' items={masters.brands} keyField='id' valueField='name'
                        manage={() => setManage('brand')} manageModelId="masterModel"
                        value={product.brand} handleChange={(val) => changeProductSelect('brand', val)} />
                </div>
                <div className="col-sm-2">
                    <DropDown label='Segment' items={masters.segments} keyField='id' valueField='name'
                        manage={() => setManage('segment')} manageModelId="masterModel"
                        value={product.segment} handleChange={(val) => changeProductSelect('segment', val)} />
                </div>
                <div className="col-sm-2">
                    <DropDown label='Gender' items={masters.genders} keyField='id' valueField='name'
                        manage={() => setManage('gender')} manageModelId="masterModel"
                        value={product.gender} handleChange={(val) => changeProductSelect('gender', val)} />
                </div>
                <div className="col-sm-2">
                    <DropDown label='Model' items={masters.models} keyField='id' valueField='name'
                        manage={() => setManage('model')} manageModelId="masterModel"
                        value={product.model} handleChange={(val) => changeProductSelect('model', val)} />
                </div>
                {/* <div className="col-sm-1">
                        
                    </div> */}
                <div className="col-sm-3">
                    <button type="button" className="btn btn-secondary m-2"
                        data-bs-toggle="modal" data-bs-target="#importMastersModel"
                        onClick={showImportDialog}>Import Masters</button>
                    <button type="button" className="btn btn-secondary m-2"
                    >Import Products</button>
                </div>
            </div>}
            {masters && <div className="row mt-3">
                <div className="col-sm-2">
                    <SelectionList label='Style' items={masters.styles} keyField='id' valueField='name'
                        style={{ height: 450 }} value={product.style}
                        manage={() => setManage('style')} manageModelId="masterModel"
                        handleChange={(val) => changeProductProp('style', val)} />
                </div>
                <div className="col-sm-2">
                    <SelectionList label='Size' items={masters.sizes} keyField='id' valueField='name'
                        style={{ height: 450 }} value={product.size}
                        manage={() => setManage('size')} manageModelId="masterModel"
                        handleChange={(val) => changeProductProp('size', val)} />
                </div>
                <div className="col-sm-2">
                    <SelectionList label='Color' items={masters.colors} keyField='id' valueField='name'
                        style={{ height: 450 }} value={product.color}
                        manage={() => setManage('color')} manageModelId="masterModel"
                        handleChange={(val) => changeProductProp('color', val)} />
                </div>
                <div className="col-sm-6">
                    {products && <DisplayTable style={{ height: 477 }} columns={['id', 'style', 'size', 'color', 'dt']} data={products} />}
                </div>
            </div>}

            <div className="modal fade" id="masterModel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">{_.startCase(manage)}s</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                onClick={e => setEditItem({ id: 0, name: '' })} aria-label="Close"></button>
                            {/* <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Close</button> */}
                        </div>
                        <div className="modal-body" style={{ maxHeight: 300 }}>
                            <table className="table" style={{ padding: 0, margin: 0 }}>
                                <thead>
                                    <tr className="table-active">
                                        {/* <th className="col-1" scope="col">ID</th> */}
                                        <th className="col-5" scope="col">Name</th>
                                        <th className="col-1" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {manage && masters[manage + 's'] && masters[manage + 's'].map((row, ind) => {
                                        return <tr key={ind}>
                                            {/* <td className="col-1">{row.id}</td> */}
                                            <td className="col-5">{row.name}</td>
                                            <td className="col-1">
                                                <a href="#edit" role="button" className="popover-test"
                                                    onClick={() => showEditItem(row)}>Edit</a>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            {editItem && <table className="table" style={{ padding: 0, margin: 0 }}>
                                <tbody>
                                    <tr>
                                        <td className="col-4">
                                            <div className="form-group">
                                                <label for="editModel" className="form-label">
                                                    {editItem.id === 0 ? 'New:' : 'Edit: ' + editOrg.name}
                                                </label>
                                                <input type="email" className="form-control" id="editModel"
                                                    value={editItem.name} onChange={e => setEditItem({ id: editItem.id, name: e.target.value })} />
                                                {/* <small id="editHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                            </div>
                                        </td>
                                        <td className="col-1">
                                            <button type="button" className="btn btn-outline-primary w-100" data-bs-dismiss="modal"
                                                onClick={e => setEditItem({ id: 0, name: '' })}>Close</button>
                                            <br />
                                            <button type="button" className="btn btn-outline-primary w-100"
                                                onClick={saveEditItem} >Save</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>}

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="importMastersModel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Import PT FIle </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                            {/* <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Close</button> */}
                        </div>
                        {!ptObj && <div className="modal-body" style={{ maxHeight: 300 }}>
                            <input type="file" name="file" onChange={selectFile} />
                            <button className="btn btn-primary" onClick={submitImportForm} >Upload PT File</button>
                        </div>}
                        {ptObj && <div className="modal-body" style={{ maxHeight: 300 }}>
                            {/* <div>{JSON.stringify(ptObj, null, 4)}</div> */}
                            <div>
                                <SelectionList label='Tabs' items={ptObj.tabs} keyField='id' valueField='name'
                                    value={ptObj.tab ? ptObj.tab : ''} handleChange={(val) => setPtObj({ ...ptObj, tab: val })} />
                                <br />
                                {ptObj.tab && <SelectionList label='Columns' items={ptObj.tab.columns} className="mt-5"
                                    value={ptObj.column} handleChange={(val) => setPtObj({ ...ptObj, column: val })} />}
                                <br />
                                {ptObj.column && <SelectionList label='Master item' items={['styles', 'sizes', 'colors']} className="mt-5"
                                    value={ptObj.master ? ptObj.master : ''} handleChange={(val) => setPtObj({ ...ptObj, master: val })} />}
                                {ptObj.column && ptObj.master && <div className='flex flex-row mt-3'>
                                    <button type="button" className="btn btn-outline-primary w-100"
                                        onClick={getMasterList}>Get items</button>
                                </div>}
                                {ptObj.column && ptObj.master && ptObj.list && <div className='flex flex-row mt-3'>
                                    <SelectionList label='List of Items' items={ptObj.list} />
                                    <br />
                                    <button type="button" className="btn btn-outline-primary w-100"
                                        onClick={e => mergeMasterItems(ptObj.master, ptObj.list)}>Check & Import</button>
                                    <button type="button" className="btn btn-outline-primary w-100"
                                        data-bs-dismiss="modal" >Close</button>
                                </div>}
                            </div>
                        </div>}
                        <div className="modal-footer">
                            {/* {editItem && <table className="table" style={{ padding: 0, margin: 0 }}>
                                <tbody>
                                        <tr>
                                            <td className="col-4">
                                                
                                            </td>
                                            <td className="col-1">
                                            <button type="button" className="btn btn-outline-primary w-100" data-bs-dismiss="modal"
                                                onClick={e => setEditItem({id: 0, name: ''})}>Close</button>
                                            </td>
                                        </tr>
                                </tbody>
                            </table>} */}

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
