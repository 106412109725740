import React from 'react'

export default function Notifications() {
    return (
        <div className="header-intro theme-bg-primary text-white py-4">
            <div className="container">
                <div className="profile-teaser row gx-lg-5 justify-content-center">
                </div>
            </div>
        </div>
    )
}
