/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react'
import { Switch, Route } from 'react-router'
import { GlobalContext } from '../../Contexts';

import './Theme.css'
// import $ from 'jquery';
// import Popper from '@popperjs/core';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/js/bootstrap.js';
// import 'bootstrap/dist/css/bootstrap.css';

import UIHelper from '../../Helpers/UIHelper'
import TopHeader from './TopHeader'
import Notifications from './Notifications'
import { Home, Products } from '../../Pages'
import { BrowserRouter } from 'react-router-dom'

// import './Main.css'
// import './Navigation'

export default function Main() {
    const { loadCookie, auth } = useContext(GlobalContext.Auth)
    const [notifications] = useState([])
    // const uiRef = useRef(null)

    React.useEffect(() => loadCookie(), [])

    return (
        <BrowserRouter>
            <header className="header">
                <TopHeader />
                {notifications.length > 0 && <Notifications />}
            </header>
            {/* <UIHelper ref={uiRef} /> */}
            {!auth.loggedIn && <div className="px-5 py-2">
                <Home />
            </div>}
            {auth.loggedIn === true && <div className="px-5 py-2">
                <Switch>
                    <Route exact path='/' component={Products} />
                    <Route exact path='/products' component={Products} />
                    {/* <Route exact path='/' render={() => <Home uiRef={uiRef.current} />} /> */}
                    {/* <Route exact path='/products' render={() => <Products uiRef={uiRef.current} />} />
                    <Route
                        path='/map/:project/:id'
                        render={({ match: { params } }) => <Products {...params} uiRef={uiRef.current} />}
                    /> */}
                </Switch>
            </div>}
            <footer className="footer fixed-bottom text-center p-2">
                <span className="text-white">&copy; 2022 @ JDR BayFashion </span>
            </footer>
        </BrowserRouter>
    )
}
