/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function SelectionList({ label, items, value, keyField, valueField, handleChange, style, count, manage, manageModelId }) {
    const handleClick = e => {
        e.preventDefault()
        manage()
    }
    return (<div>
                <div className="d-flex justify-content-between">
                    <h6>{label}</h6>
                    {count && <h6>{'Selected:' + count}</h6>}
                    {manage && <a href='#manage' type="text" className=""
                        data-bs-toggle="modal" data-bs-target={"#" + manageModelId}
                        onClick={handleClick} >Manage</a>}
                </div>
                <div className="" style={{ overflow: "auto", border: "1px solid",...style}}>
                    <div className="list-group">
                        {items && items.map((item,ind) => {
                            return <button key={ind}
                                className={"list-group-item list-group-item-action" + (value && value === item ? ' active': '')}
                                onClick={() => handleChange(item)}
                                >{valueField ? item[valueField] : item }</button>
                        })}
                    </div>
                </div>
            </div>
        
    )
}
