import React from 'react'

import { AuthContext, AuthProvider } from './AuthContext'
import { ProductsContext, ProductsProvider } from './ProductsContext'

const GlobalContextProvider = ({ children }) => {
	return (
		<ProductsProvider>
			<AuthProvider>{children}</AuthProvider>
		</ProductsProvider>
	)
}

const GlobalContext = {
	Auth: AuthContext,
	Products: ProductsContext
}

export { GlobalContextProvider, GlobalContext }
