import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Layout } from './Pages'
import reportWebVitals from './reportWebVitals'
import { CookiesProvider } from 'react-cookie'
import { GlobalContextProvider } from './Contexts';

ReactDOM.render(
	<React.StrictMode>
		<CookiesProvider>
			<GlobalContextProvider>
				<Layout />
			</GlobalContextProvider>
		</CookiesProvider>		
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
