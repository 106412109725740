import React from 'react'
import RestHelper from '../Helpers/RestHelper'
import { useCookies } from "react-cookie";

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
	const [cookies, setCookie, removeCookie] = useCookies(['web_store_auth']);
	const [ auth, setAuth ] = React.useState({ loggedIn: false })

	const loadCookie  = () => {
		if (cookies.access_token && cookies.logged_in_at) {
			RestHelper.post(`app/VerifyToken`,{token: cookies.access_token, dt: cookies.logged_in_at})
			.then((result) => {
				if (result) {
					//alert(JSON.stringify(result))
					logIn(result)
					//setCookie('access_token',cookies.access_token)
				} else {
					logOut()
					removeCookie('access_token')
					removeCookie('logged_in_at')
				}
			})
			.catch((err) => {
				removeCookie('access_token')
				removeCookie('logged_in_at')
			})
		}
	}

	const verifyLogin = (username,password) =>
		new Promise((resolve, reject) => {
			if (username && password && username.trim()!=='' && password.trim()!=='') {
				RestHelper.post(
					`app/VerifyLogin`,{username, password}
				)
					.then((result) => {
						if (result) {
							logIn(result.staff)
							setCookie('access_token',result.accessToken)
							setCookie('logged_in_at',result.loggedInAt)
							resolve(true)
						} else {
							logOut()
							removeCookie('access_token')
							removeCookie('logged_in_at')
							reject('Error: Failed to load products')
						}
					})
					.catch((err) => reject(err))				
			} else {
				resolve(false)
			}
		})

	const logIn = (user) => setAuth({ loggedIn: true, id: user.id, name: user.name, role: user.role })
	const logOut = () => {
		setAuth({ loggedIn: false, id: null, name: null, role: null })
		removeCookie('access_token')
		removeCookie('logged_in_at')
	}

	return <AuthContext.Provider value={{ auth, loadCookie, verifyLogin, logOut }}>{children}</AuthContext.Provider>
}
