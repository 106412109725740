import axios from 'axios'
// import https from 'https'

const instance = axios.create(
	{
		// httpsAgent: new https.Agent({
		// 	rejectUnauthorized: false
		// })
	}
)

const api = ''
//const api = 'http://localhost:62126'
//const api = 'https://api.goldmedal.in'

// const jsonHeader = {
// 	headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' } // Authorization: `Bearer ${localStorage.getItem('token')}`
// }
// const fileUploadHeader = {
// 	headers: { 'Content-type': 'multipart/form-data', 'Access-Control-Allow-Origin': '*' } //  Authorization: `Bearer ${localStorage.getItem('token')}`
// }

const getHeaders = (type) => {
	switch (type) {
		case 'file':
			return { headers: { 'Content-type': 'multipart/form-data', 'Access-Control-Allow-Origin': '*' } }
		case 'xml':
			return { headers: { 'Content-Type': 'application/xml', 'Access-Control-Allow-Origin': '*' } }
		default:
			return { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' } }
	}
}

const service = {
	get: (path) => {
		return new Promise((resolve, reject) => {
			instance
				.get(`${api}/${path}`, getHeaders())
				.then((response) => {
					if (response && response.data) {
						resolve(response.data)
					} else {
						reject(response.Message)
					}
				})
				.catch((error) => {
					reject(error)
				})
		})
	},
	post: (path, input, header) => {
		return new Promise((resolve, reject) => {
			instance
				.post(`${api}/${path}`, input, getHeaders(header))
				.then((response) => {
					if (response && response.data) {
						resolve(response.data)
					} else {
						reject(response.Message)
					}
				})
				.catch((error) => {
					alert(JSON.stringify(error))
					reject(error)
				})
		})
	},
	put: (path, input, header) => {
		return new Promise((resolve, reject) => {
			instance
				.put(`${api}/${path}`, input, getHeaders(header))
				.then((response) => {
					if (response && response.data) {
						resolve(response.data)
					} else {
						reject(response.Message)
					}
				})
				.catch((error) => {
					reject(error)
				})
		})
	},
	delete: (path) => {
		return new Promise((resolve, reject) => {
			instance
				.delete(`${api}/${path}`, getHeaders())
				.then((response) => {
					if (response && response.data) {
						resolve(response.data)
					} else {
						reject(response.Message)
					}
				})
				.catch((error) => {
					reject(error)
				})
		})
	}
}

export default service
